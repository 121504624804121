<template>
  <v-dialog :value="value" persistent width="850">
    <v-card>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          height=350
          :items-per-page="-1"
          :items="components"
          :search="search"
          :loading="loading"
          dense
          fixed-header
          hide-default-footer
          no-data-text="No Items to Display">

        <template #[`item.item_id`]="{ item }">
          <span v-if="item.item_id">
            {{item.item_id}}
          </span>
          <span v-else>-</span>
      </template>

      <template #[`item.qty_per_case`]="{ item }">
          <span v-if="item.qty_per_case">
            {{item.qty_per_case}}
          </span>
          <span v-else>-</span>
      </template>

      <template #[`item.last_90_days_shipped_qty`]="{ item }">
          <span v-if="item.last_90_days_shipped_qty != null">
            {{item.last_90_days_shipped_qty}}
          </span>
          <span v-else>-</span>
      </template>

          <template #top>
            <v-toolbar class="main" dark flat>
              <v-toolbar-title>{{selectedItem.item_desc}}</v-toolbar-title>
              <v-spacer/>
              <v-btn icon @click="$emit('update:value', false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
           </template>

        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'

export default {
  name: 'ComponentItems',

  mixins: [displayHelpers],

  props: ['value', 'selectedItem', 'selectedAd'],

  data() {
    return {
      search: '',
      filters: [],
      components: [],
      loading: false
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value){
          this.getShipperComponents()
        }
      }
    }
  },

  computed: {
    headers () {
      return [
        { class: 'accent', sortable: false },
        { text: 'Item Id', class: 'accent white--text', sortable: true, filterable: true, value: 'item_id', width: '100' },
        { text: 'Description', class: 'accent white--text', sortable: true, filterable: true, value: 'item_desc', width: 'auto' },
        { text: 'UPC', class: 'accent white--text', sortable: true, filterable: true, value: 'upc', width: '145' },
        { text: 'Qty', class: 'accent white--text', sortable: true, filterable: true, value: 'qty_per_case', width: '75' },
        { text: 'Pack Size', class: 'accent white--text', sortable: true, filterable: true, value: 'pack_size', width: '75' },
        { text: 'Qty Per Shipper', align: 'center', class: 'accent white--text', sortable: true, filterable: true, value: 'component_qty', width: '135' },
        { text: 'Avg. Movement', align: 'center', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty', width: '135' }
      ]
    }
  },

  methods: {
    async getShipperComponents () {
      this.components = []
      const params = {"shipper_id" : this.selectedItem.item_id}
      this.loading = true

      await this.$Ads.getShipperComponents(this.selectedAd.id, this.selectedItem.feature_id, params).then(res => {
          this.components = res.data
          this.loading = false
        }).catch(err=>{
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Shipper Components due to ${err?.response?.data?.message || err.message}` })
        })
    }
  }
}
</script>
<style scoped>
.noWrap {
  white-space: nowrap;
}
</style>
